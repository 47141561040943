<template>
  <div>
    <div class="inner-header">
      <nav class="navbar fixed-top animate__animated animate__slideInRight">
        <a  @click="$router.go(-1)" class="back-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="17.368" height="16.365" viewBox="0 0 17.368 16.365">
               <g id="Group_38360" data-name="Group 38360" transform="translate(-27.632 -34.814)" opacity="1">
                  <line id="Line_124" data-name="Line 124" x2="16" transform="translate(29 43)" fill="none" stroke="#000" stroke-width="2"/>
                  <path id="Path_51451" data-name="Path 51451" d="M.033,0l-7,7.425L.033,15" transform="translate(35.967 35.5)" fill="none" stroke="#000" stroke-width="2"/>
               </g>
            </svg>
         </a>
          <a href="javascript:void(0);" class="link-title center">{{ $route.query.tabStatus === 'Prospects' ? 'Prospect CP Details' : 'CP Details' }}</a>
      </nav>
    </div>
    <div class="inner-page animate__animated animate__slideInRight">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2">
                    <div class="card details-card mb-2">
                        <div class="card-body">
                          <div class="row">
                              <div class="col-6">
                                <span class="enq-title">Name</span>
                                <span class="enq-details">{{getBrokers.name}}</span>
                              </div>
                              <div class="col-6">
                                <span class="enq-title">Firm Name</span>
                                <span class="enq-details">{{getBrokers.firm_name}}</span>
                              </div>
                          </div>
                            <div class="row">
                                  <div class="col-6" v-if="getBrokers.email">
                                    <span class="enq-title">Email</span>
                                    <span class="enq-details">{{getBrokers.email}}</span>
                                </div>
                                <div class="col-6" v-if="getBrokers.contact_number">
                                    <span class="enq-title">Contact No</span>
                                    <span class="enq-details">{{getBrokers.contact_number}}</span>
                                </div>
                                <div class="col-6" v-if="getBrokers.empanelment_date">
                                    <span class="enq-title">Empanelment Date</span>
                                    <span class="enq-details">{{getBrokers.empanelment_date | dateFormat}}</span>
                                </div>
                                  <div class="col-6" v-if="getBrokers.rm_name">
                                    <span class="enq-title">RM</span>
                                    <span class="enq-details">{{getBrokers.rm_name}}</span>
                                </div>
                                  <div class="col-6" v-if="getBrokers.created_by">
                                    <span class="enq-title">Executive</span>
                                    <span class="enq-details">{{getBrokers.created_by}}</span>
                                </div>
                                  <div class="col-6" v-if="getBrokers.city_name">
                                    <span class="enq-title">City</span>
                                    <span class="enq-details">{{getBrokers.city_name}}</span>
                                </div>
                                  <div class="col-6" v-if="getBrokers.region">
                                    <span class="enq-title">Region</span>
                                    <span class="enq-details">{{getBrokers.region}}</span>
                                </div>
                                  <div class="col-6" v-if="getBrokers.locality">
                                    <span class="enq-title">Locality</span>
                                    <span class="enq-details">{{getBrokers.locality}}</span>
                                </div>
                                  <div class="col-6" v-if="getBrokers.rera_status">
                                    <span class="enq-title">Rera</span>
                                    <span class="enq-details">{{getBrokers.rera_status}}</span>
                                </div>
                                <div class="col-6" v-if="getBrokers.rera_status == 'Yes'">
                                    <span class="enq-title">Rera Id</span>
                                    <span class="enq-details">{{getBrokers.rera_id}}</span>
                                </div>
                                <div class="col-6" v-if="getBrokers.rera_status == 'Yes'">
                                    <span class="enq-title">Rera Expiry Date</span>
                                    <span class="enq-details">{{getBrokers.rera_expiry_date}}</span>
                                </div>
                                <div class="col-6" v-if="getBrokers.pan_card_number">
                                    <span class="enq-title">Pancard No</span>
                                    <span class="enq-details">{{getBrokers.pan_card_number}}</span>
                                </div>
                                <div class="col-6" v-if="getBrokers.transaction_id">
                                    <span class="enq-title">Transaction ID/ Cheque No.</span>
                                    <span class="enq-details">{{getBrokers.transaction_id}}</span>
                                </div>
                                <div class="col-6" v-if="getBrokers.payment_mode">
                                    <span class="enq-title">Payment Mode</span>
                                    <span class="enq-details">{{getBrokers.payment_mode}}</span>
                                </div>
                                  <div class="col-6" v-if="getBrokers.is_affiliate">
                                    <span class="enq-title">Is Affiliate</span>
                                    <span class="enq-details">{{getBrokers.is_affiliate ? 'Yes' : 'No'}}</span>
                                </div>
                                <div class="col-12" v-if="getBrokers.address">
                                    <span class="enq-title">Address</span>
                                    <read-more class="format-readmore" more-str="READ MORE"  :text="getBrokers.address" link="#" less-str="READ LESS" :max-chars="50"></read-more>
                                </div>
                                <div class="col-12 mb-5" v-if="getBrokers.followup_comment">
                                    <span class="enq-title">Follow Up Comment</span>
                                    <read-more class="format-readmore" more-str="READ MORE"  :text="getBrokers.followup_comment" link="#" less-str="READ LESS" :max-chars="50"></read-more>
                                </div>
                                <div class="col-12" v-if="getBrokers.contact_number" >
                                  <a @click="dial(getBrokers.contact_number)" class="text-center d-inline-block">
                                    <img class="d-inline-block mb-2" src="@/assets/phone.svg" alt="" width="40" height="40">
                                    <div class="detail-title">Mobile Number</div>
                                    <div class="detail-value">{{ getBrokers.contact_number }}</div>
                                  </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-btn">
                      <div class="row">
                        <div class="col-6 pr-2 mb-3" v-if="getBrokers.empanelment_form_scan_url">
                          <a class="btn btn-white btn-block" @click="viewEmpanelmentFront(getBrokers)">
                            <span class="center-fix">
                              View Empanelment Form Front 
                            </span>
                          </a>
                        </div>
                        <div class="col-6 pl-2 mb-3" v-if="getBrokers.empanelment_form_backside_scan_url">
                          <a class="btn btn-white btn-block" @click="viewEmpanelmentBack(getBrokers)">
                            <span class="center-fix">
                              View Empanelment Form Back 
                            </span>
                          </a>
                        </div>

                        <div class="col-6 pr-2 mb-3" v-if="getBrokers.receipt_scan_url">
                          <a class="btn btn-white btn-block" @click="viewPaymentProof(getBrokers)">
                            <span class="center-fix">
                              View Payment
                            </span>
                          </a>
                        </div>
                        <div class="col-6 pl-2 mb-3" v-if="getBrokers.pan_card_form_scan_url">
                          <a class="btn btn-white btn-block" @click="viewPanCard(getBrokers)">
                            <span class="center-fix">
                              View Pancard
                            </span>
                          </a>
                        </div>

                        <div class="col-6 pr-2 mb-3" v-if="getBrokers.visiting_card_url">
                          <a class="btn btn-white btn-block" @click="viewVisitingCard(getBrokers)">
                            <span class="center-fix">
                              View Visiting Card
                            </span>
                          </a>
                        </div>
                        <div class="col-6 pr-2 mb-3" v-if="getBrokers.rera_certificate_scan_url">
                          <a class="btn btn-white btn-block" @click="viewReraCertificate(getBrokers)">
                            <span class="center-fix">
                              View Rera Certificate
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="card-btn" v-if="getBrokers.status == 'Pending'">
                      <div class="row">
                        <div class="col-6 pr-2 mb-3">
                          <router-link class="btn btn-white btn-block" :to="{ name: 'Edit', params: { id: $route.params.id }}">
                            <span class="center-fix">
                              <img src="@/assets/edit.svg" width="15px" alt="" class="mr-2" />Edit
                            </span>
                          </router-link>
                        </div>
                        <div class="col-6 pl-2 mb-3">
                          <b-btn class="btn btn-white btn-block" style="border: none;" @click="deleteBroker($route.params.id)">
                              <span class="center-fix">
                                <img src="@/assets/delete.svg" width="15px" alt="" class="mr-2" />Delete
                              </span>
                          </b-btn>
                        </div>
                      </div>
                    </div>
                    <div class="card-btn" v-if="$route.query.tabStatus === 'Prospects'">
                      <div class="row">
                        <div class="col-12 mb-3 b-1">
                          <button class="btn btn-white btn-block" @click="$bvModal.show('bv-modal-comment')">
                            <span class="center-fix">
                              <img src="@/assets/comment-svg.svg" width="15px" alt="" class="mr-2" />Add Comment
                            </span>
                          </button>
                        </div>
                        <div class="col-6 pl-2 mb-3">
                          <router-link class="float-btn" :to="{ name: 'New', query: { data: getBrokers, tabStatus:$route.query.tabStatus }}">
                            <span>Onboard</span>
                            <img src="@/assets/plus.svg" width="12px" alt="" class="mr-2" />
                          </router-link>
                        </div>
                      </div>
                    </div>
                </div>
                <b-modal size="sm" id="bv-modal-comment" hide-footer hide-header centered no-close-on-esc no-close-on-backdrop>
                  <div class="d-block text-left">
                    <b-form @submit.prevent="update_commment">
                      <b-form-textarea v-model="prospects.comments" required placeholder="Follow up comment * " rows="6" max-rows="10">
                      </b-form-textarea>
                      <div style="margin-top: 10px;text-align: right;">
                         <b-button type="submit" style="margin:0;padding:10px;border: none;" variant="outline-primary">Save</b-button>
                         <b-button @click="$bvModal.hide('bv-modal-comment')" style="margin:0;padding:10px;border: none;" variant="outline-black">Close</b-button>
                      </div>
                    </b-form>
                  </div>
                </b-modal>
                   <!-- <div class="col-6 px-2">
                      <div class="card details-card ">
                        <div class="card-body p-0">
                           <span class="enq-title">Logo</span>
                          <div class="m-140 d-flex justify-content-center align-items-center">
                            <img src="@/assets/logo-cp.png" alt="" class="img-fluid center">
                          </div>
                        </div>
                      </div>
                     </div>
                    <div class="col-6 px-2">
                      <div class="card details-card">
                        <div class="card-body p-0">
                           <span class="enq-title">Icon</span>
                           <div class="m-140 d-flex justify-content-center align-items-center">
                            <img src="@/assets/icon-cp.png" alt="" class="img-fluid center">
                          </div>
                        </div>
                      </div>
                     </div> -->
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Details',
  data() {
    return {
      queryP: {},
      getBrokers: {},
      prospects: {}
    }
  },
  mounted () {
    this.fetchBrokersDetails()
  },
  methods: {
    viewPaymentProof(br) {
      window.open(br.receipt_scan_url)
    },
    dial(phone) {
      window.location ='tel:'+ phone;
    },
    viewPanCard(br) {
      window.open(br.pan_card_form_scan_url)
    },
    viewEmpanelmentFront(br) {
      window.open(br.empanelment_form_scan_url)
    },
    viewEmpanelmentBack(br) {
      window.open(br.empanelment_form_backside_scan_url)
    },
    viewVisitingCard(br) {
      window.open(br.visiting_card_url)
    },
    viewReraCertificate(br) {
      window.open(br.rera_certificate_scan_url)
    },
    update_commment() {
      this.$axios.put(`prospect_cps/${this.$route.params.id}`, {prospect_cp: this.prospects})
      .then((res) => {
          swal({
            title: "Comment Updated!",
            icon: "success",
          })
          if (this.$route.query.tabStatus === 'Prospects') {
            this.getBrokers = res.data.prospect_cp
            this.prospects.comments = null
            if (this.getBrokers.followup_comment !== null) {
              this.getBrokers.followup_comment = this.getBrokers.followup_comment.replaceAll('\n', '<br><br>').replaceAll('(Comment)', '')
            }
          } else {
            this.getBrokers = res.data.broker
          }
          this.$bvModal.hide('bv-modal-comment')
        })
        .catch((err) => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error",
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
            });
          }
        });
    },
    fetchBrokersDetails() {
    let url = ''
    if (this.$route.query.tabStatus === 'Prospects') {
      url = `prospect_cps/${this.$route.params.id}`
    } else {
      url = `brokers/${this.$route.params.id}`
    }
    this.$axios.get(url).then((res) => {
      if (this.$route.query.tabStatus === 'Prospects') {
        this.getBrokers = res.data.prospect_cp
      } else {
        this.getBrokers = res.data.broker
      }
      if (this.getBrokers.followup_comment) {
        this.getBrokers.followup_comment = this.getBrokers.followup_comment.replaceAll('\n', '<br><br>').replaceAll('(Comment)', '')
      }
      });
    },
    deleteBroker(id) {
      let vm = this;
      swal({
        title: "Delete this Broker?",
        buttons: true,
        buttons: ["No", "Yes"]
      }).then(confirm => {
        if (confirm) {
          vm.$axios
            .delete(`brokers/${id}`)
            .then(res => {
              this.$router.replace({ name: "Home" });
              swal({
                title: "Deleted!",
                icon: "success"
              });
            })
          .catch(err => {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.inner-page{
    margin-top: 72px;
}

.format-readmore {
  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
  line-height: 15px;
  color: #223345;
}
.float-btn{
  width: auto;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  gap: 12px;
  padding: 14px;
}

.float-btn span{
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #fff;
}

.detail-title{
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 4px;
  color: #444B4E;
  opacity: 40%;
}

.detail-value{
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #444B4E;
}
</style>